// Vendor
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LngDetector from "i18next-browser-languagedetector";

// App
import enGB from "locales/en-GB.json";
import esES from "locales/es-ES.json";
import svSE from "locales/sv-SE.json";
import deDE from "locales/de-DE.json";
import daDK from "locales/da-DK.json";
import fiFI from "locales/fi-FI.json";
import itIT from "locales/it-IT.json";
import frFR from "locales/fr-FR.json";
import nlNL from "locales/nl-NL.json";
import nbNO from "locales/nb-NO.json";
import ruRU from "locales/ru-RU.json";

const detectionOptions = {
  // order and from where user language should be detected
  order: [
    "querystring",
    "cookie",
    "localStorage",
    "navigator",
    "htmlTag",
    "path",
    "subdomain"
  ],

  // keys or params to lookup language from
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: "myDomain",

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement
};

const lngDetector = new LngDetector();
lngDetector.init(detectionOptions);

const initOptions = {
  debug: false, // change to true to find missing translations
  ns: ["gf365"],
  defaultNS: "gf365",
  fallbackLng: "en-GB",
  whitelist: [
    "da-DK",
    "de-DE",
    "en-GB",
    "es-ES",
    "fi-FI",
    "fr-FR",
    "it-IT",
    "nb-NO",
    "nl-NL",
    "ru-RU",
    "sv-SE"
  ],
  interpolation: { escapeValue: false },
  react: {
    wait: false,
    withRef: false,
    nsMode: "default"
  },
  resources: {
    "da-DK": { gf365: daDK },
    "de-DE": { gf365: deDE },
    "en-GB": { gf365: enGB },
    "es-ES": { gf365: esES },
    "fi-FI": { gf365: fiFI },
    "fr-FR": { gf365: frFR },
    "it-IT": { gf365: itIT },
    "nb-NO": { gf365: nbNO },
    "nl-NL": { gf365: nlNL },
    "ru-RU": { gf365: ruRU },
    "sv-SE": { gf365: svSE }
  },
  saveMissing: false,
  parseMissingKeyHandler: function(key) {
    console.log(key);
  }
};

i18next
  .use(lngDetector)
  .use(initReactI18next)
  .init(initOptions);

export default i18next;
