// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import {
  Button,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";

// App
import Auth from "common/Auth";
import CartStorage from "common/CartStorage";
import EventEmitterClient from "common/EventEmitterClient";
import logo from "images/gf365-logo.svg";
import {
  CHECKOUT_ROUTE,
  USER_BOOKINGS_ROUTE,
  START_ROUTE
} from "common/RoutesHelper";
import AutoCompleteSearch from "components/ui/AutoCompleteSearch";
import LanguageDropdown from "components/ui/LanguageDropdown";
import {
  AuthMenuLoggedIn,
  AuthMenuLoggedOut
} from "components/ui/FunctionalComponents";

class Header extends Component {
  constructor() {
    super();

    this.state = {
      menuIsOpen: false,
      cartCount: 0,
      hideControls: false,
      showLogin: false,
      showForgotPassword: false,
      showSignup: false,
      loggedIn: Auth.isAuthenticated()
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.hideControls = this.hideControls.bind(this);
    this.showControls = this.showControls.bind(this);
    this.updateCart = this.updateCart.bind(this);
  }

  componentDidMount() {
    EventEmitterClient.on("CART_UPDATE_SUCCESS", this.updateCart);
    EventEmitterClient.on("LOGIN", this.handleLogin);
    EventEmitterClient.on("LOGOUT", this.handleLogout);
    EventEmitterClient.on("SEARCH_FOCUS", this.hideControls);
    EventEmitterClient.on("SEARCH_BLUR", this.showControls);
    this.setState({ cartCount: CartStorage.count() });
  }

  componentWillUnmount() {
    EventEmitterClient.removeEventListener(
      "CART_UPDATE_SUCCESS",
      this.updateCart
    );
    EventEmitterClient.removeEventListener("LOGIN", this.handleLogin);
    EventEmitterClient.removeEventListener("LOGOUT", this.handleLogout);
    EventEmitterClient.removeEventListener("SEARCH_FOCUS", this.hideControls);
    EventEmitterClient.removeEventListener("SEARCH_BLUR", this.showControls);
  }

  handleLogin() {
    this.setState({ loggedIn: true });
  }

  handleLogout() {
    this.setState({ loggedIn: false });
  }

  hideControls() {
    this.setState({ hideControls: true });
  }

  showControls() {
    this.setState({ hideControls: false });
  }

  updateCart() {
    this.setState({ cartCount: CartStorage.count() });
  }

  render() {
    const { t } = this.props;

    const CartIcon = () => {
      let count = this.state.cartCount;
      let className =
        count > 0 ? "fa-layers-counter" : "fa-layers-counter empty";
      return (
        <span className="fa-layers fa-fw mr-2 text-body">
          <FontAwesomeIcon focusable={false} icon="shopping-basket" />
          <span className={className}>{count}</span>
        </span>
      );
    };

    return (
      <div className="Header">
        <Navbar className="d-flex" light expand="md">
          <Link
            to={START_ROUTE.path}
            className="navbar-brand mr-md-3 mr-2 d-md-inline-block"
          >
            <img src={logo} alt="Greenfee365 logo" className="logo" />
          </Link>

          <div
            className={`Header__search flex-grow-1 ${
              this.state.hideControls ? "Header__search--focus" : ""
            }`}
          >
            <AutoCompleteSearch />
          </div>

          <Link
            to={CHECKOUT_ROUTE.path}
            className="nav-link pr-2 ml-auto d-md-none text-body"
          >
            <CartIcon />
          </Link>

          <Button
            color="link"
            onClick={() => {
              this.setState({ menuIsOpen: !this.state.menuIsOpen });
            }}
            className="pr-1 pl-2 nav-link d-md-none mr-md-2"
          >
            <FontAwesomeIcon icon="bars" className="text-body" />
          </Button>

          <Collapse isOpen={this.state.menuIsOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem className="d-none d-md-inline-block">
                <Link to={CHECKOUT_ROUTE.path} className="nav-link">
                  <CartIcon />
                  <span className="ml-1">{t("checkout")}</span>
                </Link>
              </NavItem>

              {this.state.loggedIn ? (
                <Fragment>
                  <NavItem>
                    <Link to={USER_BOOKINGS_ROUTE.path} className="nav-link">
                      {t("my_bookings")}
                    </Link>
                  </NavItem>
                  <AuthMenuLoggedIn />
                </Fragment>
              ) : (
                <Fragment>
                  <LanguageDropdown
                    alignRight={true}
                    inNavbar={true}
                    showLabel={false}
                  />
                  <AuthMenuLoggedOut />
                </Fragment>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default withRouter(withTranslation()(Header));
