// Vendor
import PropTypes from "prop-types";
import React, { Component } from "react";
import { graphql } from "react-apollo";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

// App
import Auth from "common/Auth";
import EventEmitterClient from "common/EventEmitterClient";
import LocaleHelper from "common/LocaleHelper";
import { UPDATE_USER_PROFILE_MUTATION } from "common/Mutations";
import FlagIcon from "components/ui/FlagIcon";

class LanguageDropdown extends Component {
  static propTypes = {
    direction: PropTypes.string,
    toggleClasses: PropTypes.string,
    alignRight: PropTypes.bool,
    inNavbar: PropTypes.bool,
    showLabel: PropTypes.bool,
    updateLangOnChange: PropTypes.bool.isRequired,
    onChange: PropTypes.func
  };

  static defaultProps = {
    direction: "down",
    alignRight: false,
    inNavbar: false,
    onChange: () => null,
    showLabel: true,
    toggleClasses: "",
    updateLangOnChange: true
  };

  constructor(props) {
    super(props);

    const locale = LocaleHelper.getLocale();

    this.state = {
      language: locale.language,
      label: locale.label,
      country: locale.country
    };

    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.updateLocale = this.updateLocale.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    EventEmitterClient.on("LOGIN", this.updateLocale);
  }

  componentWillUnmount() {
    this._isMounted = false;
    EventEmitterClient.removeEventListener("LOGIN", this.updateLocale);
  }

  async handleLanguageChange(locale) {
    const { language } = locale;

    // update state
    this.updateLocale(locale);

    // callback
    this.props.onChange(language);

    if (this.props.updateLangOnChange) {
      // Globally update language in app
      LocaleHelper.load(language);

      // emit event
      EventEmitterClient.emit("LOCALE_CHANGE");

      // Update user in local storage if signed in
      const user = Auth.getUser();
      if (user) {
        await this.props.updateUserProfileMutation({
          variables: {
            input: {
              firstName: user.firstName,
              lastName: user.lastName,
              newsletter: user.newsletter,
              language
            }
          }
        });
        Auth.setUser({ ...user, language });
      }
    }
  }

  updateLocale(locale) {
    locale = locale || LocaleHelper.getLocale();

    if (this._isMounted) {
      this.setState({
        language: locale.language,
        label: locale.label,
        country: locale.country
      });
    }
  }

  render() {
    return (
      <UncontrolledDropdown
        inNavbar={this.props.inNavbar}
        direction={this.props.direction}
      >
        <DropdownToggle
          nav
          style={{ whiteSpace: "nowrap" }}
          className={`${this.props.toggleClasses}`}
          caret
        >
          <FlagIcon code={this.state.country} />
          {this.props.showLabel && (
            <span className="ml-2 d-inline-block">{this.state.label}</span>
          )}
        </DropdownToggle>
        <DropdownMenu right={this.props.alignRight}>
          {LocaleHelper.getLocales().map((locale, index) => (
            <DropdownItem
              key={index}
              onClick={() => {
                this.handleLanguageChange(locale);
              }}
              active={this.state.language === locale.language}
            >
              <FlagIcon code={locale.country} className="mr-2" />
              {locale.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

export const LanguageDropdownWithMutations = graphql(
  UPDATE_USER_PROFILE_MUTATION,
  {
    name: "updateUserProfileMutation"
  }
)(LanguageDropdown);

export default LanguageDropdownWithMutations;
