// Vendor
import PropTypes from "prop-types";
import React, { Component } from "react";
import { graphql, compose } from "react-apollo";
import { withTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

// App
import EventEmitterClient from "common/EventEmitterClient";
import { SIGNUP_MUTATION, NEWSLETTER_SIGNUP_MUTATION } from "common/Mutations";
import {
  CustomAlert,
  CheckoutNewsletter,
  FormLogo,
  InputFirstName,
  InputLastName,
  InputEmail,
  InputPassword,
  InputPasswordRepeat,
  SignupAgreement
} from "components/ui/FunctionalComponents";

class SignupForm extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      success: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordRepeat: "",
      newsletter: true
    };

    this.getSignupForm = this.getSignupForm.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  getSignupForm() {
    const { t } = this.props;

    return (
      <AvForm
        onValidSubmit={this.handleValidSubmit}
        onInvalidSubmit={this.handleInvalidSubmit}
        model={{ newsletter: true }}
      >
        <p className="key-figure text-muted text-center mt-3">
          {t("create_an_account")}
        </p>
        {this.state.error && (
          <CustomAlert
            text={t("email_taken_text")}
          />
        )}

        <InputFirstName />
        <InputLastName />
        <InputEmail
          onChange={() => {
            this.setState({ error: false });
          }}
        />
        <InputPassword name="signupPassword" />
        <InputPasswordRepeat
          name="signupPasswordRepeat"
          match="signupPassword"
        />

        <SignupAgreement />
        <CheckoutNewsletter />

        <Button
          className="mb-4"
          color="secondary"
          block
          size="lg"
          type="submit"
        >
          {t("create")}
        </Button>

        <hr />
        <p className="text-center mb-0">
          <span>{t("already_have_an_account")}</span>{" "}
          <a
            href="/login"
            onClick={e => {
              e.preventDefault();
              this.props.onClose();
              EventEmitterClient.emit("LOGIN_SHOW");
            }}
          >
            {t("log_in")}
          </a>
        </p>
      </AvForm>
    );
  }

  handleInvalidSubmit(event, errors, values) {
    this.setState({ errors, values, error: false });
  }

  async handleValidSubmit(event, values) {
    const { email, fname, lname, signupPassword, newsletter } = values;

    this.setState({ error: false });

    const input = {
      email: email.toLowerCase(),
      password: signupPassword,
      firstName: fname,
      lastName: lname,
      newsletter
    };

    try {
      await this.props.signupMutation({ variables: { input } });
      this.setState({ success: true });

      // If user has signed up for the newsletter
      if (newsletter) {
        this.props.newsletterSignupMutation({ variables: { email } });
      }

      return true;
    } catch (error) {
      this.setState({ error: true });
      return false;
    }
  }

  toggle() {
    this.props.onClose();
    this.setState({ error: false });
  }

  render() {
    const { t } = this.props;

    const Success = () => (
      <div className="text-center">
        <p className="text-success mt-3 mb-2">{t("account_created_text")}</p>
        <p>{t("log_in_instructions_text")}</p>
        <Button
          color="primary"
          block
          onClick={() => {
            this.props.onClose();
          }}
        >
          {t("close")}
        </Button>
      </div>
    );

    return (
      <Modal
        className="SignupForm auth-form"
        isOpen={true}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle} />
        <ModalBody>
          <FormLogo />
          {!this.state.success && this.getSignupForm()}
          {this.state.success && <Success />}
        </ModalBody>
      </Modal>
    );
  }
}

const SignupFormWithMutations = compose(
  graphql(SIGNUP_MUTATION, { name: "signupMutation" }),
  graphql(NEWSLETTER_SIGNUP_MUTATION, { name: "newsletterSignupMutation" })
)(SignupForm);

export const SignupFormBase = SignupForm;
export default withTranslation()(SignupFormWithMutations);
