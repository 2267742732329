import qs from "qs";

class URLHelper {
  static hasParam = (location, param, value) => {
    const urlParams = this.queryToState(location);
    return urlParams[param] ? urlParams[param] === value : false;
  };

  static hasAnyOfParams(location, props) {
    const urlParams = this.queryToState(location);
    let match = false;
    props.forEach(prop => {
      if (urlParams[prop]) match = true;
    });
    return match;
  }

  static isLocalhost() {
    return (
      window.location.origin.match(
        /^(http)+s?(:\/\/localhost)+|(http)+s?(:\/\/127.0.0.1)+/g
      ) !== null
    );
  }

  static queryToState = location => qs.parse(location.search.slice(1));

  static queryFromState = state => `?${qs.stringify(state)}`;

  static stateToUrl = (path, state) =>
    state ? `${path}${this.queryFromState(state)}` : "";
}

export default URLHelper;
