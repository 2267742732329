import i18next from "i18next";

class Algolia {
  appId = "KEBCCRYH1U";
  apiKey = "8a83856c103f352f948e7491e98605ee";

  // use dev index if undefined in .env
  searchIndex = process.env.REACT_APP_ALGOLIA_INDEX || "dev_Clubs";

  // use 0 days for dev, otherwise 7
  // FIXME: this number needs to be the same in frontend and backend!!!
  daysIndexed = process.env.REACT_APP_ALGOLIA_INDEX ? 7 : 0;

  areasIndex = {
    label: "areas",
    value: this.searchIndex + "_areas",
    type: "areas"
  };

  citiesIndex = {
    label: "cities",
    value: this.searchIndex + "_cities",
    type: "cities"
  };

  countriesIndex = {
    label: "countries",
    value: this.searchIndex + "_countries",
    type: "countries"
  };

  clubDirIndex = {
    label: i18next.t("club_directory"),
    value: process.env.REACT_APP_ALGOLIA_CLUB_DIR_INDEX || "test_ClubDirectory"
  };

  clubsIndex = {
    label: "golf_clubs",
    value: this.searchIndex,
    type: "clubs"
  };

  nameIndex = {
    label: "name",
    value: this.searchIndex,
    type: "name"
  };

  priceIndex = {
    label: "lowest_price",
    value: this.searchIndex + "_price_asc",
    type: "price"
  };

  rangeIndex = {
    label: "distance",
    value: this.searchIndex,
    type: "distance",
    locationEnabled: true
  };

  sortableIndices = [this.nameIndex, this.rangeIndex, this.priceIndex];
}

export default new Algolia();
