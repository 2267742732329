// Vendor
import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { StripeProvider } from "react-stripe-elements";
import { CloudinaryContext } from "cloudinary-react";

// App
//import registerServiceWorker from "./registerServiceWorker";
import { unregister } from "registerServiceWorker";
import Layout from "components/app/Layout";
import ApolloClientProvider from "common/ApolloClientProvider";
import initFontawesomeLib from "config/fontawesome";
import CloudinaryHelper from "common/CloudinaryHelper";
import ErrorBoundary from "components/ui/ErrorBoundary";
import "config/i18next";
import "styles/index.css";
import "payment-webfont/style.css";
import URLHelper from "common/URLHelper";

initFontawesomeLib();

Sentry.init({
  dsn: "https://20144ab3a54c411db86641df903a2267@sentry.io/1441000",
  environment: URLHelper.isLocalhost() ? "development" : "production"
});

const apiKey =
  process.env.REACT_APP_GOLFERS_STRIPE_KEY ||
  "pk_test_76SawXJpZFbgHI6oTt8iZWlo";

ReactDOM.render(
  <BrowserRouter>
    <StripeProvider apiKey={apiKey}>
      <ApolloProvider client={ApolloClientProvider.client}>
        <CloudinaryContext
          cloudName="greenfee365"
          dpr="auto"
          defaultImage={CloudinaryHelper.FALLBACK_IMG}
          className="h-100"
        >
          <ErrorBoundary global={true}>
            <Layout />
          </ErrorBoundary>
        </CloudinaryContext>
      </ApolloProvider>
    </StripeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

//registerServiceWorker();
unregister();
