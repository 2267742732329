/**
 * Helper class to pass data to and trigger events in Google Tag Manager
 */
class GoogleTagManagerHelper {
  constructor() {
    window.dataLayer = window.dataLayer || [];
  }

  /**
   * Push an shopping cart update to data layer
   * @param {string} event "addToCard" or "removeFromCart"
   * @param {*} item Item to add or remove
   */
  registerCartChange(event, item) {
    if (event !== "addToCart" && event !== "removeFromCart") {
      // console.warn(`${event} not a valid tracking event.`);
      return;
    }
    const ecomEvent = event === "addToCart" ? "add" : "remove";

    let products = [];
    for (let rate of item.appliedRates) {
      products.push({
        name: `${item.club.name} ${rate.rateType}`,
        id: `${item.club._id}-${item.teeTime.formattedTime}-${rate.rateType}`,
        price: rate.price,
        variant: item.club.name,
        category: rate.rateType,
        quantity: 1
      });
    }

    window.dataLayer.push({
      event: event,
      ecommerce: {
        [ecomEvent]: {
          products
        }
      }
    });
  }

  /**
   * Push the completed order to Google Analytics via Google Tag Manager
   * @param {*[]} cartItems   All items ordered
   * @param {string} orderId
   */
  checkoutCart(cartItems, orderId) {
    let products = [];
    let orderSum = 0;

    for (let item of cartItems) {
      for (let rate of item.appliedRates) {
        products.push({
          name: `${item.club.name} ${rate.rateType}`,
          id: `${item.club._id}-${item.teeTime.formattedTime}-${rate.rateType}`,
          price: rate.price,
          variant: item.club.name,
          category: rate.rateType,
          quantity: 1
        });
        orderSum += rate.price;
      }
    }

    window.dataLayer.push({
      event: "purchase",
      orderSum,
      orderId,
      ecommerce: {
        purchase: {
          actionField: {
            id: orderId,
            revenue: orderSum
          },
          products
        }
      }
    });
  }
}

export default new GoogleTagManagerHelper();
