import moment from "moment";

// TODO: lazy load
import "moment/locale/es";
import "moment/locale/sv";
import "moment/locale/de";
import "moment/locale/da";
import "moment/locale/fi";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/nl";
import "moment/locale/nb";
import "moment/locale/ru";
import "moment/locale/en-gb";
import Auth from "common/Auth";
import CookieHelper from "common/CookieHelper";
import i18next from "config/i18next";

const _locales = [
  {
    language: "en-GB",
    label: "English"
  },
  {
    language: "es-ES",
    label: "Español"
  },
  {
    language: "sv-SE",
    label: "Svenska"
  },
  {
    language: "de-DE",
    label: "Deutsch"
  },
  {
    language: "da-DK",
    label: "Dansk"
  },
  {
    language: "fi-FI",
    label: "Suomi"
  },
  {
    language: "fr-FR",
    label: "Français"
  },
  {
    language: "it-IT",
    label: "Italiano"
  },
  {
    language: "nl-NL",
    label: "Nederlands"
  },
  {
    language: "nb-NO",
    label: "Norsk"
  },
  {
    language: "ru-RU",
    label: "русский"
  }
];

class LocaleHelper {
  static locales = _locales.map(locale => {
    return {
      ...locale,
      country: locale.language.substring(3).toLowerCase()
    };
  });

  static dayAndMonthFormat(date) {
    date = typeof date === "object" ? date : moment(date);
    let dateString = date.format("ll");

    let index = dateString.indexOf(".");

    // some locales start with day of month
    // some of these locales have a dot after the day of month
    // e.g. german: 4. Jan. 2019
    // we need to check that the dot appears after this
    // trimming the contents of the string after the dot
    if (index > 2) {
      return dateString.substring(0, index);
    }

    // if no dot is removed we only remove the year
    const regex = new RegExp("(\\,|\\.)?\\s?" + date.format("YYYY"), "g");
    return dateString.replace(regex, "");
  }

  static getPrice(number, currency = "EUR") {
    const isInt = n => Number(n) === n && n % 1 === 0;

    if (!number) return 0;
    return number.toLocaleString(this.getLocale().language, {
      style: "currency",
      minimumFractionDigits: isInt(number) ? 0 : 2,
      maximumFractionDigits: 2,
      currency
    });
  }

  static getLocale() {
    const locale = this.getLocales().find(l => l.language === i18next.language);
    const defaultLocale = this.locales.find(
      l => l.language === i18next.options.fallbackLng[0]
    );
    return locale ? locale : defaultLocale;
  }

  // return clone (immutable)
  static getLocales() {
    return [...this.locales];
  }

  static loadFromUser() {
    const user = Auth.getUser();
    if (user) this.load(user.language);
  }

  static load(language) {
    language = language || this.getLocale().language;
    i18next.changeLanguage(language);
    moment.locale(language);
    CookieHelper.setCookie("i18next", language);
  }
}

export default LocaleHelper;
