import moment from "moment";
import GTMHelper from "./GoogleTagManagerHelper";
import { hasSessionStorage } from "common/StorageHelper";

const cartKey = "Greenfee365Cart";

class CartStorage {
  constructor() {
    this.cart = [];
    if (hasSessionStorage) {
      let sessionCart = sessionStorage.getItem(cartKey);
      if (sessionCart) this.cart = JSON.parse(sessionCart);
    }
  }

  /**
   * Adds a tee time to storage for future reference
   * Sorts the added teetimes by date ascending
   *
   * @param {Object} cartItems
   */
  add(cartItem) {
    const {
      appliedRates,
      buggies,
      buggyIncluded,
      club,
      course,
      players,
      teeTime
    } = cartItem;

    this.cart.push({
      appliedRates,
      buggies,
      buggyIncluded,
      club,
      course,
      players,
      teeTime
    });

    GTMHelper.registerCartChange("addToCart", cartItem);

    this.cart.sort((a, b) => {
      if (a.teeTime.formattedTime < b.teeTime.formattedTime) return -1;
      if (a.teeTime.formattedTime > b.teeTime.formattedTime) return 1;
      return 0;
    });

    this.save();
  }

  isEmpty() {
    return this.cart.length === 0;
  }

  hasOldItems() {
    return this.items().some(item =>
      moment(item.teeTime.formattedTime).isBefore(moment())
    );
  }

  item(courseId, time) {
    return this.items().find(item => {
      return (
        item.course._id === courseId && item.teeTime.formattedTime === time
      );
    });
  }

  items() {
    return this.cart;
  }

  count() {
    return this.cart.length;
  }

  remove(cartItem) {
    this.cart = this.cart.filter(e => e !== cartItem);
    GTMHelper.registerCartChange("removeFromCart", cartItem);
    this.save();
  }

  removeAll() {
    this.cart = [];
    this.save();
  }

  save() {
    if (hasSessionStorage)
      sessionStorage.setItem(cartKey, JSON.stringify(this.cart));
  }

  update(cartItem) {
    let index = this.cart.findIndex(i => i === cartItem);
    if (this.cart[index]) {
      this.cart[index] = cartItem;
      this.save();
    }
  }

  postCheckout(orderId) {
    GTMHelper.checkoutCart(this.cart, orderId);
    this.removeAll();
  }
}

export default new CartStorage();
