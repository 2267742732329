// Vendor
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

// App
import EventEmitterClient from "common/EventEmitterClient";
import Auth from "common/Auth";
import LocaleHelper from "common/LocaleHelper";
import {
  routesMatch,
  PASSWORD_RESET_ROUTE,
  VERIFY_EMAIL_ROUTE,
  START_ROUTE,
  GOLF_CLUB_ROUTE
} from "common/RoutesHelper";
import Header from "components/app/Header";
import Body from "components/app/Body";
import Footer from "components/app/Footer";
import LoginForm from "components/ui/auth/LoginForm";
import SignupForm from "components/ui/auth/SignupForm";
import ForgotPasswordForm from "components/ui/auth/ForgotPasswordForm";
import { appendScript } from "common/Scripts";
import ErrorBoundary from "components/ui/ErrorBoundary";
import URLHelper from "common/URLHelper";

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLogin: false,
      showForgotPassword: false,
      showSignup: false
    };

    this.loadZendesk = this.loadZendesk.bind(this);
    this.loadRecaptcha = this.loadRecaptcha.bind(this);
    this.showForgotPassword = this.showForgotPassword.bind(this);
    this.showLogin = this.showLogin.bind(this);
    this.showSignup = this.showSignup.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  // Append external scripts on mount
  componentDidMount() {
    // append external scripts to header
    if (!URLHelper.isLocalhost()) {
      this.loadZendesk();
      this.loadRecaptcha();
    }

    // init language from user on mount
    if (Auth.isAuthenticated()) {
      LocaleHelper.loadFromUser();
    } else {
      LocaleHelper.load();
    }

    // add listeners
    EventEmitterClient.on("FORGOT_PASSWORD_SHOW", this.showForgotPassword);
    EventEmitterClient.on("SIGNUP_SHOW", this.showSignup);
    EventEmitterClient.on("LOGIN_SHOW", this.showLogin);
    EventEmitterClient.on("LOGOUT", this.handleLogout);
  }

  componentWillUnmount() {
    // Remove listeners on unmount
    EventEmitterClient.removeEventListener(
      "FORGOT_PASSWORD_SHOW",
      this.showForgotPassword
    );
    EventEmitterClient.removeEventListener("SIGNUP_SHOW", this.showSignup);
    EventEmitterClient.removeEventListener("LOGIN_SHOW", this.showLogin);
    EventEmitterClient.removeEventListener("LOGOUT", this.handleLogout);
  }

  loadRecaptcha() {
    appendScript(
      "https://www.google.com/recaptcha/api.js",
      "recaptcha-snippet",
      true,
      true
    );
  }

  loadZendesk() {
    const { t } = this.props;
    appendScript(
      "https://static.zdassets.com/ekr/snippet.js?key=0f903061-2745-4bde-a531-010cf94bc510",
      "ze-snippet",
      false,
      true
    )
      .then(() => {
        window.zE(() => window.zE.setLocale("en"));
      })
      .catch(() =>
        EventEmitterClient.emit("SYSTEM_ALERT", {
          color: "danger",
          message: t("customer_service_unavailable_text"),
          dismissable: true
        })
      );
  }

  showForgotPassword() {
    this.setState({ showForgotPassword: true });
  }

  showLogin() {
    this.setState({ showLogin: true });
  }

  showSignup() {
    this.setState({ showSignup: true });
  }

  handleLogout(gotoStart) {
    const { location, history } = this.props;
    const isNotStart = !routesMatch(location.pathname, START_ROUTE.path);
    if (isNotStart && gotoStart) {
      history.push(START_ROUTE.path);
    }
  }

  render() {
    const path = this.props.location.pathname;
    const { showLogin, showSignup, showForgotPassword } = this.state;

    const hideHeader =
      routesMatch(path, PASSWORD_RESET_ROUTE.path) ||
      routesMatch(path, VERIFY_EMAIL_ROUTE.path) ||
      path === START_ROUTE.path;

    const hideFooter =
      routesMatch(path, PASSWORD_RESET_ROUTE.path) ||
      routesMatch(path, VERIFY_EMAIL_ROUTE.path);

    const footerClassName = routesMatch(path, GOLF_CLUB_ROUTE.path)
      ? "mt-0"
      : "mt-5";

    return (
      <div className="Layout">
        {showLogin && (
          <LoginForm
            onClose={() => {
              this.setState({ showLogin: false });
            }}
          />
        )}
        {showSignup && (
          <SignupForm
            onClose={() => {
              this.setState({ showSignup: false });
            }}
          />
        )}
        {showForgotPassword && (
          <ForgotPasswordForm
            onClose={() => {
              this.setState({ showForgotPassword: false });
            }}
          />
        )}

        {!hideHeader && <Header />}
        <ErrorBoundary>
          <Body />
        </ErrorBoundary>
        {!hideFooter && <Footer className={footerClassName} />}
      </div>
    );
  }
}

export default withRouter(withTranslation()(Layout));
