// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { AvGroup, AvField, AvForm } from "availity-reactstrap-validation";

// App
import Auth from "common/Auth";
import EventEmitterClient from "common/EventEmitterClient";
import { EMAIL_VALIDATOR, PASSWORD_VALIDATOR } from "common/Validators";
import { FormLogo, CustomAlert } from "components/ui/FunctionalComponents";

class LoginForm extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    showForgotPassword: PropTypes.bool,
    showSignup: PropTypes.bool
  };

  static defaultProps = {
    showForgotPassword: true,
    showSignup: true
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      values: [],
      email: "",
      password: "",
      error: false,
      errorType: undefined
    };

    this.toggle = this.toggle.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleInvalidSubmit(event, errors, values) {
    this.setState({ errors, values, error: false });
  }

  async handleValidSubmit(event, values) {
    this.setState({ error: false, errorType: undefined });

    try {
      await Auth.login(values.loginEmail, values.loginPassword);
      this.props.onClose();
      return true;
    } catch (error) {
      if(error.type === "LOCAL_STORAGE_NOT_SUPPORTED"){
        this.setState({ error: true, errorType: "LOCAL_STORAGE_NOT_SUPPORTED"});
      }
      else if (
        error.graphQLErrors &&
        error.graphQLErrors[0].message === "EMAIL_NOT_VERIFIED"
      ) {
        this.setState({ error: true, errorType: "EMAIL_NOT_VERIFIED"});
      } else {
        this.setState({ error: true, errorType: "LOGIN_FAILED" });
      }
      return false;
    }
  }

  toggle() {
    this.props.onClose();
    this.setState({ error: false });
  }

  render() {
    const { t, showSignup, showForgotPassword, onClose } = this.props;
    const { error, errorType} = this.state;

    const LocalStorageError = () => (
      <>
        Login has been disabled for this device. Enable{" "}
        <a
          href="https://en.wikipedia.org/wiki/Web_storage"
          target="_blank"
          rel="noopener noreferrer"
        >
          Web Storage
        </a> or update your browser to log in.
      </>
    )

    return (
      <Modal className="LoginForm auth-form" isOpen={true} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle} />
        <ModalBody>
          <AvForm
            onValidSubmit={this.handleValidSubmit}
            onInvalidSubmit={this.handleInvalidSubmit}
          >
            <FormLogo />
            <p className="key-figure text-muted text-center mt-3">
              {t("log_in_to_greenfee365")}
            </p>
            {error && errorType === "LOCAL_STORAGE_NOT_SUPPORTED" && <CustomAlert text={<LocalStorageError />} />}
            {error && errorType === "LOGIN_FAILED" && <CustomAlert text={t("log_in_failed_text")} />}
            {error && errorType === "EMAIL_NOT_VERIFIED" && (
              <CustomAlert text={t("log_in_unverified_email_text")} />
            )}

            <AvGroup className="mb-3">
              <div className="icon-input-group icon-input-group-lg">
                <AvField
                  className="form-control-lg"
                  name="loginEmail"
                  autoComplete="username"
                  placeholder={t("email")}
                  validate={EMAIL_VALIDATOR}
                />
                <FontAwesomeIcon
                  focusable={false}
                  size="sm"
                  className="text-black-50"
                  icon="envelope"
                />
              </div>
            </AvGroup>

            <AvGroup className="mb-4">
              <div className="icon-input-group icon-input-group-lg">
                <AvField
                  className="form-control-lg"
                  type="password"
                  name="loginPassword"
                  autoComplete="current-password"
                  placeholder={t("password")}
                  validate={PASSWORD_VALIDATOR}
                />
                <FontAwesomeIcon
                  focusable={false}
                  size="sm"
                  className="text-black-50"
                  icon="lock"
                />
              </div>
            </AvGroup>

            <Button color="secondary" block size="lg" type="submit">
              {t("log_in")}
            </Button>

            {showForgotPassword && (
              <div className="text-center">
                <Button
                  className="mt-3"
                  color="link"
                  onClick={() => {
                    onClose();
                    EventEmitterClient.emit("FORGOT_PASSWORD_SHOW");
                  }}
                >
                  {t("forgot_password")}
                </Button>
              </div>
            )}

            {showSignup && (
              <div>
                <hr />
                <p className="text-center mb-0">
                  <span>{t("dont_have_an_account")}</span>{" "}
                  <a
                    href="/register"
                    onClick={e => {
                      e.preventDefault();
                      onClose();
                      EventEmitterClient.emit("SIGNUP_SHOW");
                    }}
                  >
                    {t("sign_up")}
                  </a>
                </p>
              </div>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
    );
  }
}

export const LoginFormBase = LoginForm;

export default withTranslation()(LoginForm);
