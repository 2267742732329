import { matchPath } from "react-router-dom";

export const routesMatch = (currentPath, destinationPath) =>
  matchPath(currentPath, {
    path: destinationPath,
    exact: true,
    strict: false
  }) !== null;

export const ABOUT_ROUTE = {
  path: "/info/about",
  name: "about"
};

export const CHECKOUT_ROUTE = {
  path: "/checkout",
  name: "checkout"
};

export const CLUB_DIR_ROUTE = {
  path: "/club-directory",
  name: "club-directory"
};

export const COOKIE_POLICY_ROUTE = {
  path: "/info/cookie-policy",
  name: "cookie-policy"
};

export const GOLF_AREA_ROUTE = {
  base: "/golf-area/",
  path: "/golf-area/:slug",
  name: "golf-area"
};

export const GOLF_CLUB_ROUTE = {
  base: "/golf-clubs/",
  path: "/golf-clubs/:slug",
  name: "golf-clubs"
};

export const ORDER_CONFIRMATION_ROUTE = {
  base: "/order-confirmation/",
  path: "/order-confirmation/:orderId",
  name: "order-confirmation"
};

export const PASSWORD_RESET_ROUTE = {
  base: "/password-reset/",
  path: "/password-reset/:email/:resetToken",
  name: "password-reset"
};

export const PRIVACY_POLICY_ROUTE = {
  path: "/info/privacy-policy",
  name: "privacy-policy"
};

export const SEARCH_ROUTE = {
  path: "/search",
  name: "search"
};

export const START_ROUTE = {
  path: "/",
  name: "start"
};

export const TERMS_OF_SERVICE_ROUTE = {
  path: "/info/terms-of-service",
  name: "terms-of-service"
};

export const USER_BOOKINGS_ROUTE = {
  path: "/user/bookings",
  name: "bookings"
};

export const USER_NOTIFICATIONS_ROUTE = {
  path: "/user/notifications",
  name: "notifications"
};

export const USER_PROFILE_ROUTE = {
  path: "/user/profile",
  name: "profile"
};

export const VERIFY_EMAIL_ROUTE = {
  base: "/verify-email/",
  path: "/verify-email/:email/:token",
  name: "verify-email"
};
