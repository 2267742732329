// Vendor
import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";
import { withTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

// App
import EventEmitterClient from "common/EventEmitterClient";
import { COOKIE_POLICY_ROUTE } from "common/RoutesHelper";
import Routes from "components/app/Routes";
import ScrollToTop from "components/ui/ScrollToTop";
import SystemAlert from "components/ui/SystemAlert";
import { hasLocalStorage, hasSessionStorage } from "common/StorageHelper";

class Body extends Component {
  constructor() {
    super();
    this.state = { alerts: [] };
    this.addAlert = this.addAlert.bind(this);
    this.closeAlerts = this.closeAlerts.bind(this);
  }

  componentDidMount() {
    if (!hasLocalStorage || !hasSessionStorage) {
      this.addAlert({
        color: "warning",
        message: (
          <p className="d-inline mb-0">
            You are using this site with restricted functionality. Enable{" "}
            <a
              href="https://en.wikipedia.org/wiki/Web_storage"
              target="_blank"
              rel="noopener noreferrer"
            >
              Web Storage
            </a>{" "}
            or update your browser to fully use this site.
          </p>
        )
      });
    }
    EventEmitterClient.on("SYSTEM_ALERT", this.addAlert);
    EventEmitterClient.on("REMOVE_ALERTS", this.closeAlerts);
  }

  componentWillUnmount() {
    EventEmitterClient.removeEventListener("SYSTEM_ALERT", this.addAlert);
    EventEmitterClient.removeEventListener("REMOVE_ALERTS", this.closeAlerts);
  }

  addAlert(alert) {
    if (alert.closeOthers) {
      this.setState({ alerts: [alert] });
    } else {
      this.setState(prevState => ({
        alerts: [...prevState.alerts, alert]
      }));
    }

    // Scroll up to top so user can see the alert
    window.scroll(0, 0);
  }

  closeAlerts() {
    this.setState({ alerts: [] });
  }

  render() {
    const { t } = this.props;

    return (
      <div className="Body">
        {this.state.alerts.map((alert, index) => (
          <SystemAlert
            key={index}
            color={alert.color}
            message={alert.message}
            dismissable={alert.dismissable}
          />
        ))}
        <CookieConsent
          containerClasses="d-flex align-items-center justify-content-between p-4"
          disableStyles={true}
          buttonText={t("i_understand")}
        >
          <Trans i18nKey="cookie_consent_text">
            This site is using{" "}
            <Link to={COOKIE_POLICY_ROUTE.path}>cookies</Link> to enhance your
            experience.
          </Trans>
        </CookieConsent>
        <ScrollToTop>
          <Routes />
        </ScrollToTop>
      </div>
    );
  }
}

export default withTranslation()(Body);
